const moment = require("moment");
/**
 * 获取日期属于一年中的哪一周
 * @param {string} date 格式 YYYY-MM-DD
 * @returns {number}
 */
let getWeekNumber = (date) => {
    let firstYear = moment(date).startOf('isoWeek').format('Y') // 那一日期的周一年份
    let endYear = moment(date).endOf('isoWeek').format('Y') // 那一日期的周日年份
    if (firstYear !== endYear) { // 如果不相等，说明跨年了，直接返回1，表示新年第一周
        return 1
    }
    let arr = date.split('-')
    var now = new Date(parseInt(arr[0]), parseInt(arr[1]) - 1, parseInt(arr[2])),
        year = now.getFullYear(),
        month = now.getMonth(),
        days = now.getDate();
//那一天是那一年中的第多少天
    for (var i = 0; i < month; i++) {
        days += getMonthDays(year, i);
    }

//那一年第一天是星期几
    var yearFirstDay = new Date(year, 0, 1).getDay() || 7;

    var week = null;
    if (yearFirstDay == 1) {
        week = Math.ceil(days / yearFirstDay);
    } else {
        days -= (7 - yearFirstDay + 1);
        week = Math.ceil(days / 7) + 1;
    }

    return week;
}

/**
 * 第n年的第n周，获取开始和结束时间 （星期一为一周的开始），例如2020-1
 * @param weekstr 格式 年份-周，如：2022-1，表示2022年第1周
 * @returns {[string,string]}
 */
let getWeekDateRange = (weekstr) => {
    let year = weekstr.split("-")[0]
    let weekNo = weekstr.split("-")[1]
    // 此年1号是星期几
    let oneday = new Date(year + '-01-01').getDay() //0-6
    // 方便计算，当为星期天时为7
    if (oneday == 0) {
        oneday = 7
    }

    let one_fistday;
    let one_lastday;
    // 如果1号刚好是星期一
    if (oneday == 1) {
        one_fistday = year + '-01-01'
        one_lastday = year + '-01-07'
    } else {
        let jj = 8 - oneday
        one_fistday = (year - 1) + '-12-' + (31 - oneday + 2 > 9 ? 31 - oneday + 2 : '0' + (31 - oneday + 2))
        one_lastday = year + '-01-' + (jj > 9 ? jj : '0' + jj)
    }

    let fistday;
    let lastday;
    // 如果刚好是第一周
    if (weekNo == 1) {
        fistday = one_fistday
        lastday = one_lastday
    } else {
        fistday = addDate(one_lastday, (weekNo - 2) * 7 + 1)
        lastday = addDate(one_lastday, (weekNo - 1) * 7)
    }
    return [fistday, lastday]
}


/**
 * 日期加减法  date参数为计算开始的日期，days为需要加的天数
 * 格式:addDate('2017-1-11',20)
 * @param date
 * @param days
 * @returns {string}
 */
function addDate(date, days) {
    var d = new Date(date);
    d.setDate(d.getDate() + days);
    var m = d.getMonth() + 1;
    return d.getFullYear() + '-' + (m > 9 ? m : '0' + m) + '-' + (d.getDate() > 9 ? d.getDate() : '0' + d.getDate());
}

/**
 * 获取某一年份的某一月份的天数
 *
 * @param {Number} year
 * @param {Number} month
 */
function getMonthDays(year, month) {
    return [31, null, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month] || (isLeapYear(year) ? 29 : 28);
}

/**
 * 判断年份是否为润年
 *
 * @param {Number} year
 */
function isLeapYear(year) {
    return (year % 400 == 0) || (year % 4 == 0 && year % 100 != 0);
}

module.exports = {
    getWeekNumber,
    getWeekDateRange,
}
<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">

        <span>组织架构：</span>
        <!-- <el-cascader ref="firstCascader" :options="ckdTreeData" :props="defaultProps" v-model="class_id"
          @change="changeDate" :show-all-levels="false" clearable placeholder="请选择" filterable
          style="width: 150px;margin-right:10px" size="small">
        </el-cascader> -->
        <tchtree v-model="class_id" @change="changeDate"></tchtree>
        <span style="margin-left:20px">选择周：</span>
        <!--        <el-date-picker  @change="getData" v-model="sign_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">-->
        <!--        </el-date-picker>-->

        <el-date-picker @change="changeDate" size="small" v-model="year_week" type="week" :format="showWeek"
                        :firstDayOfWeek="1"></el-date-picker>

        <el-button v-if="$store.state.pmid == 214 || $store.state.pmid == 221"
                   icon="el-icon-download"
                   size="small"
                   type="primary"
                   @click="exportWeekReport" style="margin-left: 10px;">导出
        </el-button>

      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>学生周报</el-breadcrumb-item>
          <el-breadcrumb-item>周报统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent" style="width: 100%;height: calc( 100% - 80px );">
      <div style="text-align:right;padding-right:50px">
        <el-button @click="setDataType1" size="small"
                   :icon="dataType1 == 'chart' ? 'el-icon-notebook-2' : 'el-icon-data-analysis'">{{
            dataType1 == 'chart' ? '表格查看' :
                '图表查看'
          }}
        </el-button>

      </div>
      <div v-show="dataType1 == 'chart'" id="chartBox" style="width: 100%;height: 500px;"></div>
      <div v-show="dataType1 == 'xls'" style="padding:50px">
        <!-- <el-button @click="downXls" size="small" >下载数据表</el-button>
       -->
        <table class="dttable" id="dktable">
          <tr>
            <td class="tdheader">序号</td>
            <td class="tdheader">班级名称</td>
            <td class="tdheader">班主任</td>
            <td class="tdheader">系部</td>
            <td class="tdheader">提交率</td>
          </tr>

          <template v-if="dataList.length">
            <tr v-for="(item, idx) in dataList" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>{{ item.class_name }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.yx_name }}</td>
              <td>{{ item.tj_rate }}%</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="5" align="center">暂无数据</td>
          </tr>

        </table>

      </div>

      <div style="text-align:right;padding-right:50px">
        <el-button @click="setDataType2" size="small"
                   :icon="dataType2 == 'chart' ? 'el-icon-notebook-2' : 'el-icon-data-analysis'">{{
            dataType2 == 'chart' ? '表格查看' :
                '图表查看'
          }}
        </el-button>

      </div>

      <div v-show="dataType2 == 'chart'" id="chartBox1" style="width: 100%;height: 500px;"></div>
      <div v-show="dataType2 == 'xls'" style="padding:50px">
        <!-- <el-button @click="downXls" size="small" >下载数据表</el-button>
       -->
        <table class="dttable" id="dktable">
          <tr>
            <td class="tdheader">序号</td>
            <td class="tdheader">班级名称</td>
            <td class="tdheader">班主任</td>
            <td class="tdheader">系部</td>
            <td class="tdheader">审批率</td>
          </tr>

          <template v-if="dataList1.length">
            <tr v-for="(item, idx) in dataList1" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>{{ item.class_name }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.yx_name }}</td>
              <td>{{ item.sp_rate }}%</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="5" align="center">暂无数据</td>
          </tr>

        </table>

      </div>
    </div>


  </div>
</template>

<script>
import util from "../../../utils/util.js"
import tchtree from '../../com/tchTree.vue'
import datejs from "@/utils/datePlugin";
import {njsyxx_statistics_export_excel} from "../../../../public/js/Export2Excel";

export default {
  components: {
    tchtree
  },
  data() {
    return {
      myClasses: [],
      class_id: "",
      sign_date: util.formatDate(new Date()),
      dataList: [],
      dataList1: [],
      year_week: '',
      report_year: '',
      week: '',
      weekStart: "",
      weekEnd: "",
      ckdTreeData: [],

      defaultProps: {
        label: 'name',
        value: 'id',
        children: 'children',
        emitPath: false,
        checkStrictly: true
      },
      dataType1: "chart",
      dataType2: "chart"
    }
  },
  mounted() {
    // $("#chartBox").height(($(window).height() - 220) / 2)
    // $("#chartBox1").height(($(window).height() - 220) / 2)
    this.getTree()
    this.getNowDate()
  },
  computed: {
    showWeek() {
      return `${this.report_year} 第 ${this.week} 周`
    },
  },
  methods: {
    setDataType1() {
      if (this.dataType1 == "chart") {
        this.dataType1 = "xls"
      } else {
        this.dataType1 = "chart"
      }
    },
    setDataType2() {
      if (this.dataType2 == "chart") {
        this.dataType2 = "xls"
      } else {
        this.dataType2 = "chart"
      }
    },
    getMyClasses() {

      this.$http.post("/api/get_tch_cls_data").then(res => {
        this.myClasses = [].concat(res.data.bj_list).concat(res.data.sxxz_list)
        // this.class_id = this.myClasses[0].id
        this.getNowDate()
      })
    },
    getTree() {

      this.$http.post("/api/sys_office_tree", {parent_id: 0, biye: this.biye}).then(res => {
        this.treeData = res.data.tree
        this.ckdTreeData = this.treeData
      })
    },
    getNowDate() {
      this.$http.post("/api/find_date").then(d => {
        this.report_year = d.data.year
        this.week = d.data.week
        this.year_week = new Date()
        this.weekStart = d.data.onMonday.split(' ')[0]
        this.weekEnd = d.data.sunday.split(' ')[0]

        this.getData()
        this.getData1()
      })
    },
    changeDate(e) {

      let str = this.$moment(this.year_week).utcOffset(480).format("yyyy-WW")
      this.weekStart = this.$moment(this.year_week).utcOffset(480).format("yyyy-MM-DD")
      this.weekEnd = this.$moment(this.year_week).add(6, "day").utcOffset(480).format("yyyy-MM-DD")

      let arr = str.split('-')
      this.report_year = arr[0]
      this.week = arr[1]
      /*let weekStart = this.$moment(e).format("YYYY-MM-DD")
      this.week = datejs.getWeekNumber(weekStart)
      this.report_year = this.$moment(weekStart).endOf('isoWeek').format('Y')*/
      this.getData()
      this.getData1()
    },
    changeClass(e) {
      this.getData()
      this.getData1()
    },
    getData() {
      this.$http.post("/api/stu_week_statistics", {
        report_year: this.report_year,
        week: this.week,
        start: this.weekStart,
        end: this.weekEnd,
        class_id: this.class_id
      }).then(res => {
        res.data && res.data.map(a => {
          if (a.tj_rate) {
            a.tj_rate = this.$keepTwoDecimal(parseFloat(a.tj_rate) * 100)
            if (a.tj_rate > 100) {
              a.tj_rate = 100
            }
          }
          a.class_name = a.NAME
          a.NAME += a.username
        })
        this.dataList = res.data
        this.initChart()
      })
    },
    getData1() {
      this.$http.post("/api/stu_sp_week_statistics", {
        report_year: this.report_year,
        week: this.week,
        class_id: this.class_id
      }).then(res => {
        res.data && res.data.map(a => {
          if (a.sp_rate) {
            a.sp_rate = this.$keepTwoDecimal(parseFloat(a.sp_rate) * 100)
          }
          a.class_name = a.NAME
          a.NAME += a.username
        })
        this.dataList1 = res.data
        this.initChart1()
      })
    },
    initChart() {
      let dataAxis = this._lo.map(this.dataList, 'NAME')
      let data = this._lo.map(this.dataList, 'tj_rate')


      let option = {
        title: {
          text: '班级/小组周报提交率(%)',
        },
        grid: {containLabel: true,},
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {

            if (params.length > 0) {
              var result = params[0].name + "<br>";
              params.forEach(function (item) {
                result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
              });
              return result;
            }
          },
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {inside: false, color: '#000', alignWithLabel: true, rotate: 60},
          axisTick: {show: true},
          axisLine: {show: true},
          z: 10
        },
        yAxis: {
          max: 100,//最大值
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: {show: true},
          axisTick: {show: true},
          axisLabel: {color: '#000'}
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              color: '#33a9ff',

              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: '#83bff6' },
              //   { offset: 0.5, color: '#188df0' },
              //   { offset: 1, color: '#188df0' }
              // ])
            },
            emphasis: {
              itemStyle: {
                color: '#33a9ff',

                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   { offset: 0, color: '#2378f7' },
                //   { offset: 0.7, color: '#2378f7' },
                //   { offset: 1, color: '#83bff6' }
                // ])
              }
            },
            data: data,
            label: {
              show: true,
              position: 'inside',
              // formatter: function (params) { //标签内容
              //   return params.value + '%'
              // },
            },
          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('chartBox'));
      myChart.setOption(option)

    },
    initChart1() {

      let dataAxis = this._lo.map(this.dataList1, 'NAME')
      let data = this._lo.map(this.dataList1, 'sp_rate')


      let option = {
        title: {
          text: '班级/小组周报审核率(%)',
        },
        grid: {containLabel: true,},
        tooltip: {
          trigger: 'axis',

          formatter: function (params) {

            if (params.length > 0) {
              var result = params[0].name + "<br>";
              params.forEach(function (item) {
                result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
              });
              return result;
            }
          },

        },
        xAxis: {
          data: dataAxis,
          axisLabel: {inside: false, color: '#000', alignWithLabel: true, rotate: 60},
          axisTick: {show: true},
          axisLine: {show: true},
          z: 10
        },
        yAxis: {
          max: 100,//最大值
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: {show: true},
          axisTick: {show: true},
          axisLabel: {color: '#000'}
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {offset: 0, color: '#83bff6'},
                {offset: 0.5, color: '#188df0'},
                {offset: 1, color: '#188df0'}
              ])
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {offset: 0, color: '#2378f7'},
                  {offset: 0.7, color: '#2378f7'},
                  {offset: 1, color: '#83bff6'}
                ])
              }
            },
            data: data,

            label: {
              show: true,
              position: 'inside',
              formatter: function (params) { //标签内容
                return params.value + '%'
              },
            },
          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('chartBox1'));
      myChart.setOption(option)

    },
    exportWeekReport() {
      this.$confirm('默认导出当前周数据，导出其他周数据，请选择周', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/api/export_statistics_xls', {
          report_year: this.report_year,
          week: this.week,
          start: this.weekStart,
          end: this.weekEnd,
          class_id: this.class_id,
          type: 'week'
        }).then(res => {
          let excelData = [
            ['周报统计导出'],
            ['序号', '时间', '班级', '系部', '届次', '班主任', '周报提交率', '周报审核率', '周报提交总数']
          ]
          res.data && res.data.map((r, index) => {
            if (r.sp_rate) {
              r.sp_rate = this.$keepTwoDecimal(parseFloat(r.sp_rate) * 100)
            }
            if (r.tj_rate) {
              r.tj_rate = this.$keepTwoDecimal(parseFloat(r.tj_rate) * 100)
            }
            r.class_name = r.NAME
            r.year_month = Number(this.report_year) + ' 年第 ' + Number(this.week) + ' 周'
            excelData.push([
              ++index,
              r.year_month,
              r.class_name,
              r.yx_name,
              r.jc_name,
              r.username,
              r.tj_rate + '%',
              r.sp_rate + '%',
              r.tj_count
            ])
          })
          njsyxx_statistics_export_excel(excelData, 9)
        })
      }).catch(() => {
      });
    },

  },

};
</script>

